import "./index.css"

function Index() {

    document.addEventListener("DOMContentLoaded", function() {
        var element = document.getElementById("typewriter");
        var text = "Próximamente...";
        var index = 0;
      
        function typeWriter() {
          if (index < text.length) {
            element.innerHTML += text.charAt(index);
            index++;
            setTimeout(typeWriter, 200); // Velocidad de aparición de letras (200ms)
          }
        }
      
        typeWriter();
      });


    return(

        <div className="principal px-5 d-flex flex-column justify-content-md-between ">        

            <div className="d-flex flex-column">
                <div className="d-flex" ><img  className="isologo mx-auto corazon"  src="IKS_Recursos_Web_Isologo.webp"></img></div>            
                <div className="d-flex"><img  className="texto-1 mx-auto mt-3 mb-1" src="IKS_Recursos_Web_Texto1.webp"></img></div>            
                <div className="d-flex"><img  className="texto-2 mx-auto" src="IKS_Recursos_Web_Texto2.webp"></img></div>                        
                <div className="d-flex divisor mt-3 mb-4 mx-auto" ></div>
                
            </div>
            <h2 id="proximamente" className="mt-2">Próximamente...</h2>
            <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between ms-1 ms-sm-3 px-0">
                <div className="d-flex flex-column  align-items-center flex-md-row">
                    <div className="d-flex col-md-5 "><img  className="texto-4 mt-5 mt-md-4 mb-md-4 mx-auto" src="IKS_Recursos_Web_Texto4.webp"></img></div>
                    <div className="d-flex col-md-4"><img  className="logotipo mx-auto" src="IKS_Recursos_Web_Logotipo.webp"></img></div>                
                    <div className="d-flex col-md-3"><img  className="texto-5 my-md-4 mx-auto" src="IKS_Recursos_Web_Texto5.webp"></img></div>
                </div>    
                <div className="row justify-content-center  mt-5 mt-md-3 mb-1 ms-1 ms-sm-3 px-md-5">
                    <div className="d-flex col-2 ms-3 pe-0 "><img  className="logos mx-1" src="IKS_Recursos_Web_Facebook.webp"></img></div>              
                    <div className="d-flex col-2 ms-2 px-0 "><img  className="logos mx-2" src="IKS_Recursos_Web_Twitter.webp"></img></div>
                    <div className="d-flex col-6 px-0 "><img  className="logos-texto mx-1" src="IKS_Recursos_Web_Texto6.webp"></img></div>
                </div>
            </div>

        </div>
        
        
    );

}

export default Index;
