
import './App.css';
import Index from './components/proximamente';

function App() {  

  return (
    <Index></Index>
  );
}

export default App;
